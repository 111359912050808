.folder-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.file-card {
    width: 200px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.file-card .MuiCardContent-root {
    display: flex;
    align-items: center;
    gap: 10px;
}
